import React from 'react';
import classnames from 'classnames';

import styles from './HeadingPropertyApp.module.scss';

export const HeadingPropertyApp = ({ headingSmall, headingLarge }) => {
  return (
    <div className={classnames(styles.headingPropertyApp, 'ta-container')}>
      <div className={styles.inner}>
        {headingSmall ? (
          <h2
            className={styles.headingSmall}
            dangerouslySetInnerHTML={{ __html: headingSmall }}
          ></h2>
        ) : null}
        {headingLarge ? (
          <h1
            className={styles.headingLarge}
            dangerouslySetInnerHTML={{ __html: headingLarge }}
            style={{ marginBottom: '40px'}}
          ></h1>
        ) : null}
      </div>
    </div>
  );
};

export default HeadingPropertyApp;
