import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import PropertyApp, { PropertyAppProvider } from 'react-property-app';
import wrapper from 'static/images/wrapper.svg';
// eslint-disable-next-line import/no-unresolved
import HeadingPropertyApp from 'components/HeadingPropertyApp';

// eslint-disable-next-line
import styles from './landForSale.module.scss';

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      content
      title
    }
  }
`;

export const LandForSale = props => {
  const [loading, setLoading] = useState(true);
  const [landForSaleData, setLandForSaleData] = useState([]);
  const [masterPlanUrl, setMasterPlanUrl] = useState('');
  const [designGuidelinesUrl, setDesignGuidelinesUrl] = useState('');
  const [pageState, setPageState] = useState({
    page: null,
  });

  useEffect(() => {
    let requestCancelled = false;
    const executePageQuery = async () => {
      try {
        const interactiveUrl = window?.traffic?.api?.url;
        if (interactiveUrl) {
          const response = await fetch(window?.traffic?.api?.url);
          const json = await response.json();
          const assetpath = json?.assetpath;

          // eslint-disable-next-line no-console
          if (json?.DATA?.[0]?.precincts?.[0]?.['land-for-sale']?.length > 0) {
            setLandForSaleData([json.DATA[0].precincts[0]['land-for-sale']]);
            setMasterPlanUrl(`${assetpath}${json.DATA[0]?.masterplan_flyer}`);
            setDesignGuidelinesUrl(
              `${assetpath}${json.DATA[0]?.design_guidelines_flyer}`,
            );
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
      const { match, client } = props;
      const uri = match.url.substr(1);
      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        setPageState({ ...pageState, page });
      }
      setLoading(false);
    };

    executePageQuery();

    return () => {
      requestCancelled = true;
    };
  }, [props]); //eslint-disable-line

  if (loading) return null;

  return (
    <>
      <PropertyAppProvider>
        <Helmet>
          <title itemProp="name" lang="en">
            Wilton Greens | Land for sale
          </title>
        </Helmet>
        {landForSaleData?.length === 0 && (
          <HeadingPropertyApp headingLarge='<span className="bold">Land</span> for Sale' />
        )}
        {landForSaleData?.length > 0 && (
          <div className="landForSale">
            <PropertyApp />
          </div>
        )}
        {landForSaleData?.length === 0 && (
          <div style={{ padding: '0 0 100px 20px' }}>
            <p>Sorry, there are no land listings at the moment.</p>
          </div>
        )}
      </PropertyAppProvider>
      {landForSaleData?.length === 0 && (
        <section
          className="lfs no-land-for-sale"
          style={{
            background: `url(${wrapper}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
        >
          <div className="ta-container">
            <div className="ta-row">
              <div className="lfs--map ta-neighbourhood--stage-1">
                <div className="lfs--downloads">
                  <h4>Downloads</h4>
                  <a
                    href={masterPlanUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ta-btn ta-btn__small ta-btn__secondary"
                  >
                    {/* <span className="ta-icon" style={`background-image: url(${Download});`}></span> */}
                    Masterplan
                  </a>
                  <a
                    href={designGuidelinesUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ta-btn ta-btn__small ta-btn__secondary"
                  >
                    {/* <span className="ta-icon" style={`background-image: url(${Download});`}></span> */}
                    Design guidelines
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default withApollo(LandForSale);
